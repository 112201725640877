@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer components {
  .file-box {
    @apply relative box-border bg-cover bg-center w-full h-fit flex justify-center my-2.5;
  }

  .head-title {
    @apply font-canelablack text-mat-ef-revolution-primary-500 text-center text-4xl md:text-6xl;
  }

  .services-head-title {
    @apply text-center font-canelablack lg:text-6xl text-3xl md:text-5xl text-mat-ef-revolution-primary-500;
  }

  .privacy-title-text {
    @apply font-efSans text-base text-justify font-bold uppercase text-mat-ef-revolution-primary-500 mb-6;
  }

  .font-canelablack-primary {
    @apply font-canelablack text-mat-ef-revolution-primary-500;
  }

  .font-efSans-primary {
    @apply font-efSans text-mat-ef-revolution-primary-500;
  }

  .font-efSans-secondary {
    @apply font-efSans text-mat-ef-revolution-secondary-500;
  }

  .dialog-close-button {
    @apply absolute top-0 right-0 scale-150 w-6 h-6 outline-0;
  }

  .btn-dialog {
    @apply text-base h-12 px-20 w-full md:w-auto;
  }

  .footer-v2-text {
    @apply font-efSans font-bold grid gap-6 list-none text-white uppercase text-sm leading-6 tracking-[1.4px] col-span-1;
  }

  .footer-v2-link {
    @apply no-underline text-white hover:w-max hover:border-b-2 hover:border-white h-6;
  }

  .newsletter {
    @apply font-efSans flex flex-col gap-6 font-bold list-none text-white uppercase max-w-[285px] text-sm leading-6 tracking-[1.4px] col-span-1 pt-6 md:pt-0;
  }

  .card-content-slogan {
    @apply absolute left-[30px] bottom-[22px] font-black text-sm tracking-[2.8px] align-[initial] uppercase text-left text-white inline-block mb-0 before:content-none before:w-3 before:h-3 before:bg-mat-ef-green-secondary-500 before:rounded-[50%] before:inline-block before:align-[initial] before:mr-2;
  }

  .new-appointment-btn {
    @apply font-efSans text-base text-mat-ef-revolution-primary-500 font-semibold text-center bg-white rounded-2xl px-4 py-2 w-fit h-fit hover:cursor-pointer;
  }

  .appointment-form {
    @apply overflow-y-auto overflow-x-hidden pr-0 md:pr-6 mt-4 max-h-full md:max-h-[40vh] relative;
  }

  .license-plate-appointment {
    @apply text-mat-ef-revolution-primary-500 text-sm font-normal mt-[2px] py-[1px] px-1 w-max text-center rounded border-mat-ef-revolution-grey-two-500 shadow-sm shadow-mat-ef-revolution-grey-two-500;
  }

  .appointment-form-title {
    @apply text-mat-ef-revolution-secondary-500 font-efSans text-xl font-medium md:my-5;
  }

  .font-efSans-primary {
    @apply font-efSans text-mat-ef-revolution-primary-500;
  }

  .btn-delete-vehicle {
    @apply whitespace-nowrap text-mat-ef-revolution-primary-500 text-base text-center font-efSans cursor-pointer bg-mat-ef-revolution-supergrey-500 px-4 py-1 rounded-2xl font-semibold w-full md:w-fit;
  }

  .btn-rounded-btn {
    @apply whitespace-nowrap text-mat-ef-revolution-primary-500 text-base text-center font-efSans cursor-pointer bg-white px-4 py-1 rounded-2xl font-semibold w-full;
  }

  .notification-panel-wrapper {
    @apply max-w-[1060px] bg-mat-ef-revolution-supergrey-500 rounded-xl m-6 mt-[-200px] md:mt-[-160px] flex flex-col md:flex-row z-30 h-fit shadow-md w-full relative;
  }

  .unread-badge {
    @apply flex rounded-full text-white text-center font-normal font-efSans text-xs min-w-[1.25rem] h-5 px-0.5 leading-5 my-auto bg-mat-ef-revolution-secondary-500;
  }

  .vehicle-card-status {
    @apply absolute top-3.5 right-[50%] translate-x-[50%] w-fit rounded py-[2px] px-4 bg-mat-ef-green-secondary-800 text-white;
  }

  .view-container {
    @apply mt-10 lg:mt-14 px-0 md:px-6 lg:px-28 pb-7 lg:pb-14;
  }

  .user-initials {
    @apply relative flex rounded-full text-white text-center font-efSans text-[10px] w-5 h-5 px-0.5 leading-5 my-auto uppercase bg-mat-ef-revolution-primary-500 cursor-pointer relative flex rounded-full text-white text-center font-efSans text-[10px] w-5 h-5 px-0.5 leading-5 my-auto uppercase bg-mat-ef-revolution-primary-500 cursor-pointer;
  }

  .menu-column {
    @apply font-efSans text-sm text-mat-ef-revolution-primary-500 cursor-pointer;
  }

  .user-menu-column {
    @apply mt-1 mb-0 flex flex-col items-center text-center text-[11px] leading-tight break-words md:break-normal md:items-start md:text-sm;
  }

  .have-no-card {
    @apply w-full h-80 bg-mat-ef-revolution-grey-200 rounded-xl flex flex-col justify-center items-center mt-4 md:mt-14;
  }

  .vehicle-extended-icon {
    @apply max-w-[20px] min-w-[20px] text-mat-ef-revolution-grey-two-500 max-w-[20px] min-w-[20px];
  }

  .vehicle-boxes-rounded {
    @apply border rounded-xl px-6 py-4 border-mat-ef-revolution-supergrey-500 text-mat-ef-revolution-primary-500;
  }

  .vehicle-expansion-panel-text {
    @apply w-[calc(100%_-_30px)] ml-5 text-mat-ef-revolution-primary-500;
  }

  .image-slider-btn {
    @apply absolute w-10 md:w-14 h-10 md:h-14 transform -translate-y-1/2 bg-mat-ef-revolution-primary-500 inset-y-1/2 border-none cursor-pointer rounded-full;
  }

  .card-flex-rounded {
    @apply flex flex-row items-center gap-6 py-7 px-10 mb-5 border border-mat-ef-revolution-supergrey-500 rounded-lg #{!important};
  }

  .card-flex-rounded-icon {
    @apply min-w-[36px] min-h-[36px] text-3xl items-center justify-self-start relative text-mat-ef-revolution-primary-500 font-medium;
  }
}
